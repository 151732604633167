import AOS from 'aos';;
import '../../components/navigation/navigation.jsx';
import '../../components/cookie/cookie.js';
import '../../node_modules/tiny-slider/dist/tiny-slider.js';
import './home.scss'
import {tns} from "tiny-slider";

AOS.init();

const slider = tns({
  container: document.querySelector('.slider'),
  autoplay: true,
  navPosition: 'bottom',
  controls: false,
  autoplayButtonOutput: false

  // prevButton: document.querySelector('.btn-prev'),
  // nextButton: document.querySelector('.btn-next')
});